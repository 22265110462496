import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import cn from 'classnames';

import { GeneralContext } from '../../context/general';
import { Input } from '../Input';
import s from './s.module.css';

export function LoginPage() {
  const location = useLocation();
  const { api, generalActions, generalSelectors } = React.useContext(
    GeneralContext
  );
  const [state, setState] = React.useState({
    username: '',
    password: '',
  });

  if (generalSelectors.isLogged) {
    const { from } = location.state || { from: { pathname: '/' } };

    return <Redirect to={from} />;
  }

  const changeHandler = (e) => {
    const { name, value } = e.currentTarget;
    setState({ ...state, [name]: value });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    api('/auth/login', {
      method: 'POST',
      body: JSON.stringify(state),
    })
      .then((resp) => {
        generalActions.setToken(resp.access_token);
        generalActions.setUser(resp.user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <form onSubmit={submitHandler} className={s.form}>
      <Input
        className={s.field}
        type="text"
        name="username"
        value={state.username}
        onChange={changeHandler}
        required
      >
        Email
      </Input>
      <Input
        className={s.field}
        type="password"
        name="password"
        value={state.password}
        onChange={changeHandler}
        required
      >
        Password
      </Input>
      <button type="submit" className={cn('button', s.button)}>
        Login
      </button>
    </form>
  );
}
