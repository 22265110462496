import React from 'react';

import { GeneralContext } from '../../context/general';
import s from './s.module.css';
import { VoterListPage } from '../VoterListPage';
import { Header } from '../Header';

export function DashboardPage() {
  const { api } = React.useContext(GeneralContext);

  const [state, setState] = React.useState({
    constituentGroups: [],
    selectedConstituentGroupId: 0
  });

  React.useEffect(() => {
    api(`/voters/listConstituentGroups`)
      .then((data) => {
        setState({ constituentGroups: data, selectedConstituentGroupId: data[0].id });
      })
      .catch((err) => console.log(err));
  }, [api]);

  const changeConstituentGroup = (constituentGroupId) => {
    setState({ ...state, selectedConstituentGroupId: constituentGroupId });
  }

  return (
    <>
      <Header></Header>
      <header className={s.header}>
        <nav>
          {
            state.constituentGroups.length === 0 ?
              <div> Loading Constituent Groups </div> :
              state.constituentGroups.map(constituentGroup => {
                return <div
                  key={constituentGroup.id}
                  className={constituentGroup.id === state.selectedConstituentGroupId ? `button ${s.activeLink}` : "button button-clear"}
                  onClick={(e) => changeConstituentGroup(constituentGroup.id)}
                >
                  {constituentGroup.name}
                </div>
              })
          }
        </nav>
      </header>
      <VoterListPage constituentGroupId={state.selectedConstituentGroupId} />
    </>
  );
}
