import React from 'react';
import { NavLink } from 'react-router-dom';

import { GeneralContext } from '../../context/general';
import s from './s.module.css';

export function Header() {
  const { generalSelectors, generalActions } = React.useContext(GeneralContext);

  if (!generalSelectors.isLogged) {
    return null;
  }
  console.log(generalSelectors.user)
  return (
    <header className={s.header}>
      <nav>
        <NavLink
          to="/"
          exact
          className="button button-clear"
          activeClassName={s.activeLink}
        >
          List Management
        </NavLink>
        <NavLink
          to="/issue-preview"
          className="button button-clear"
          activeClassName={s.activeLink}
        >
          Issue Preview
        </NavLink>
      </nav>
      <div className={s.userWrap}>
        <span className={s.userName}>{generalSelectors?.user?.username}</span>
        <button
          type="button"
          className="button"
          onClick={() => {
            generalActions.setUser(null);
            generalActions.setToken(null);
          }}
        >
          Logout
        </button>
      </div>
    </header>
  );
}
