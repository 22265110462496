import React from 'react';
import cn from 'classnames';

import { Modal } from '../Modal';
import { Input } from '../Input';
import { CloseIcon } from '../Icons';
import s from './s.module.css';

export const ConfirmModal = ({ title, resolve, reject }) => {
  const [text, setText] = React.useState('');

  const submitHandler = (e) => {
    e.preventDefault();
    resolve(text);
  };

  return (
    <Modal heading={title} closeHandler={reject}>
      <button type="button" className={s.closeButton} onClick={reject}>
        <CloseIcon />
      </button>
      <form onSubmit={submitHandler} className={s.form}>
        <button type="submit" className={cn('button', s.button)}>
          Yes
        </button>
      </form>
    </Modal>
  );
};
