import React from 'react';
import ReactDOM from 'react-dom';
import { nanoid } from 'nanoid';
import { Notification } from '../components/Notification';
import { generateErrorMessages } from '../utils/error';
import { noop } from '../utils/noop';

const tokenName = 'competition-admin-auth-token';

/** @type {import('react').Context<TGeneralContext>} */
export const GeneralContext = React.createContext({
  api: noop,
  notificationActions: {
    /** @type {TGeneralContext$addNotification} */
    addNotification: noop,
    /** @type {TGeneralContext$removeNotification} */
    removeNotification: noop,
    /** @type {TGeneralContext$clearNotifications} */
    clearNotifications: noop,
  },
});

const notificationsNode = document.querySelector('#notifications-root');

export const GeneralProvider = ({ children }) => {
  /** @type {[(TGeneralContext$user|null|undefined), React.Dispatch<React.SetStateAction<(TGeneralContext$user|null)>>]} */
  const [user, setUser] = React.useState();
  /** @type {[TGeneralContext$notification[], React.Dispatch<React.SetStateAction<TGeneralContext$notification[]>>]} */
  const [notifications, setNotifications] = React.useState([]);

  const api = React.useCallback(
    async (
      url,
      { headers = {}, data, ...restOptions } = {},
      processResponseMethod = 'json'
    ) => {
      /**
       * @type {Object}
       */
      const requestHeaders = Object.entries({
        'Content-Type': 'application/json',
        ...headers,
      }).reduce((a, [k, v]) => (v == null ? a : { ...a, [k]: v }), {});

      const token = localStorage.getItem(tokenName);
      if (token && !requestHeaders.Authorization && url !== '/auth/login') {
        requestHeaders.Authorization = `Bearer ${token}`;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${url}`,
        {
          headers: new Headers(requestHeaders),
          mode: 'cors',
          ...restOptions,
        }
      );

      if (!processResponseMethod) {
        return response;
      }

      if (!response.ok) {
        throw await response[processResponseMethod]();
      }

      // const contentType = response.headers.get('content-type');
      // if (!contentType || !contentType.includes('application/json')) {
      //   return null;
      // }

      return response[processResponseMethod]();
    },
    []
  );

  const setToken = React.useCallback((t) => {
    if (t) {
      localStorage.setItem(tokenName, t);
    } else if (localStorage[tokenName]) {
      localStorage.removeItem(tokenName);
    }
  }, []);

  React.useEffect(() => {
    const token = localStorage.getItem(tokenName);
    if (token) {
      api('/auth/me')
        .then((resp) => {
          setToken(resp.access_token);
          setUser(resp.user);
        })
        .catch(() => {
          setUser(null);
        });
    } else {
      setUser(null);
    }
  }, []);

  /** @type {TGeneralContext$addNotification} */
  const addNotification = React.useCallback(
    (message, { level = 'error', timeout, override = false } = {}) => {
      const prettyMessage = generateErrorMessages(message);
      if (prettyMessage) {
        const nItem = { id: nanoid(), message: prettyMessage, level, timeout };

        setNotifications((ns) => [...(override ? [] : ns), nItem]);
      }
    },
    [setNotifications]
  );

  const removeNotification = React.useCallback(
    (id) => {
      setNotifications((prevNotifications) =>
        prevNotifications.filter((n) => n.id !== id)
      );
    },
    [setNotifications]
  );

  const clearNotifications = () => {
    setNotifications([]);
  };

  return (
    <GeneralContext.Provider
      value={{
        api,
        generalSelectors: {
          user,
          isLogged: !!user,
          isUserFetched: user !== undefined,
        },
        generalActions: {
          setUser,
          setToken,
        },
        notificationActions: {
          addNotification,
          removeNotification,
          clearNotifications,
        },
      }}
    >
      {children}
      {notificationsNode &&
        ReactDOM.createPortal(
          <>
            {notifications.map((n) => (
              <Notification key={n.id} n={n} onClose={removeNotification} />
            ))}
          </>,
          notificationsNode
        )}
    </GeneralContext.Provider>
  );
};
