import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { GeneralContext } from '../../context/general';

import { LoginPage } from '../LoginPage';
import { PrivateRoute } from '../PrivateRoute';
import s from './s.module.css';
import { DashboardPage } from '../DashboardPage';
import { IssuePreviewPage } from '../IssuePreviewPage';

export function App() {
  const { generalSelectors } = React.useContext(GeneralContext);

  if (!generalSelectors.isUserFetched) {
    return 'Fetching user';
  }

  return (
    <>

      <main className={s.main}>
        <Switch>
          <PrivateRoute exact path="/" component={DashboardPage} />
          <PrivateRoute exact path="/issue-preview" component={IssuePreviewPage} />

          <Route path="/login">
            <LoginPage />
          </Route>
          <Redirect to="/" />
        </Switch>
      </main>
    </>
  );
}
