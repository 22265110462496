import React from 'react';

import { GeneralContext } from '../../context/general';
import { reducer, generateInitialState } from '../../utils/reducer';
import { useQueryParams } from '../../hooks/queryParams';

import { ConfirmModal } from '../ConfirmModal';
import {
  BanIcon,
} from '../Icons';
import Tooltip from '../Tooltip';
import { Pagination } from '../Pagination';
import { Input } from '../Input';
import s from './s.module.css';

const takeDefault = 30;

function debounce(func, wait, immediate) {
  let timeout;

  return function executedFunction(...args) {
    const context = this;

    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

export function VoterListPage({ constituentGroupId, ...rest }) {
  const [query, setQuery] = useQueryParams();

  const { api, notificationActions } = React.useContext(GeneralContext);
  const [state, dispatchState] = React.useReducer(
    reducer,
    generateInitialState({ items: [], count: 0, addressToAdd: '' })
  );
  const take = query.take ? Number(query.take) : takeDefault;
  const skip = query.skip ? Number(query.skip) : 0;


  React.useEffect(() => {
    dispatchState({ type: 'start' });

    const queryParams = new URLSearchParams();
    if (take) queryParams.set('take', String(take));
    if (skip) queryParams.set('skip', String(skip));

    if (constituentGroupId) {
      api(`/voters/list/${constituentGroupId}?${queryParams.toString()}`)
        .then((data) => {
          dispatchState({ type: 'success', data: { items: data, count: data.length,  addressToAdd: '' } })
        })
        .catch((err) => dispatchState({ type: 'fail', error: console.log(err) }));
    }
  }, [api, take, skip, constituentGroupId]);

  const changeHandler = (item) => {
    dispatchState({
      type: 'success',
      data: {
        ...state.data,
        items: state.data.items.filter((dataItem) => dataItem.id != item.id),
        count: state.data.count--
      },
    });
  };

  const addAddressChangeHandler = (e) => {
    const { name, value } = e.currentTarget;
    dispatchState({ type: 'success', data: { ...state.data, [name]: value } })
  };

  const addAddressSubmitHandler = (e) => {
    api(`/voters`, {
      method: 'POST',
      body: JSON.stringify({
        constituentGroupId: constituentGroupId,
        address: `${state.data.addressToAdd}`
      })
    })
      .then((item) => {
        const newItems = state.data.items.concat([item]);
        dispatchState({
          type: 'success', data: {
            items: newItems,
            count: newItems.length,
            addressToAdd: ''
          }
        })
      })
      .catch((err) =>
        notificationActions.addNotification(err, { timeout: Infinity })
      );
  };

  return (
    <>
      <div className={s.filters}>
        <Input
          style={{ marginLeft: 8, minWidth: 240 }}
          placeholder="Address to add to the list"
          name="addressToAdd"
          value={state.data.addressToAdd}
          onChange={addAddressChangeHandler}
        />

        <button
          style={{ marginTop: 8, marginLeft: 20 }}
          type="button"
          className="button"
          onClick={addAddressSubmitHandler}
        > ADD </button>
      </div>
      {state.loaded ? (
        <table className={s.table}>
          <thead>
            <tr>
              <th>Address</th>
              <th>Weight</th>
              <th align="right" style={{ paddingRight: 16 }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {state.data.count ? (
              state.data.items.map((item) => (
                <TableRow
                  key={item.id}
                  item={item}
                  changeCallback={changeHandler}
                />
              ))
            ) : (
                <tr>
                  <td colSpan={5} style={{ padding: 16, textAlign: 'center' }}>
                    List empty
                </td>
                </tr>
              )}
          </tbody>
        </table>
      ) : (
          'loading...'
        )}
      {/*<Pagination skip={skip} take={take} total={state.data.count} />*/}
    </>
  );
}

function TableRow({ item, changeCallback }) {
  const { api, notificationActions } = React.useContext(GeneralContext);
  const [openBanModal, setOpenBanModal] = React.useState(false);

  const banHandler = () => setOpenBanModal(true);

  const submitBanHandler = (reason) => {
    api(`/voters/${item.id}`, {
      method: 'DELETE'
    })
      .then(() => changeCallback(item))
      .catch((err) =>
        notificationActions.addNotification(err, { timeout: Infinity })
      );
    setOpenBanModal(false);
  };

  return (
    <>
      <tr
        key={item.id}
      >
        <td>{item.address}</td>
        <td>{item.weight}</td>

        <td align="right">
          <Tooltip
            title="Remove"
            text="Remove address from list"
          >
            <button
              type="button"
              className="button button-icon"
              onClick={() => banHandler()}
            >
              <BanIcon />
            </button>
          </Tooltip>
        </td>
      </tr>
      {openBanModal && (
        <ConfirmModal
          title="Are you sure?"
          resolve={submitBanHandler}
          reject={() => setOpenBanModal(false)}
        />
      )}
    </>
  );
}
