import React from 'react';

import { GeneralContext } from '../../context/general';
import s from './s.module.css';
import { Header } from '../Header';
import { Input } from '../Input';

export function IssuePreviewPage() {
  const { notificationActions, api } = React.useContext(GeneralContext);

  const [state, setState] = React.useState({
    issueId: "",
    previewData: { }
  });

  const issueIdChangeHandler = (e) => {
    const { name, value } = e.currentTarget;
          setState({ ...state, [name]: value })
  };

  const PreviewIssueSubmitHandler = (e) => {
    api(`/polls/${state.issueId}/preview`, {
      method: 'GET',
    })
      .then((item) => {
        setState({
          issueId: "",
          previewData: item
        })
      })
      .catch((err) =>
        notificationActions.addNotification(err, { timeout: Infinity })
      );
  };

  return (
    <>
      <Header></Header>
      <div className={s.filters}>
        <Input
          style={{ marginLeft: 8, minWidth: 240 }}
          placeholder="File name for preview poll data"
          name="issueId"
          value={state.issueId}
          onChange={issueIdChangeHandler}
        />

        <button
          style={{ marginTop: 8, marginLeft: 20 }}
          type="button"
          className="button"
          onClick={PreviewIssueSubmitHandler}
        > Preview </button>
      </div>

      <div>
        <pre>{JSON.stringify(state.previewData, null, 2)}</pre>
      </div>

    </>
  );
}
